<template>
  <div v-if="$apollo.loading">Loading...</div>
  <div v-else class="container">
    <b-form class="form" @submit.stop.prevent="submit">
      <div
        id="kt_subheader"
        class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
      >
        <div
          class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        >
          <!--begin::Info-->
          <div class="d-flex align-items-center flex-wrap mr-2">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
              Product Data Management- Catalogs
            </h5>
            <!--end::Page Title-->
            <!--begin::Action-->
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>
            <span class="text-muted font-weight-bold mr-4"> </span>
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>
            <!--end::Action-->
          </div>
          <div class="d-flex align-items-center">
            <a class="btn btn-alert mr-2" @click="$router.go(-1)">Back</a>

            <button type="submit" class="btn btn-success mr-2">Save</button>
          </div>
          <!--end::Info-->
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <v-card dark class="h-100">
            <div>
              <span class="card-icon">
                <i class="flaticon2-chat-1 text-dark"></i>
              </span>
              <v-card-title
                >Catalog Editor <v-spacer> </v-spacer>
                <v-btn
                  color="warning"
                  dark
                  :disabled="defaultChannel"
                  @click="channelDelete"
                >
                  Delete
                </v-btn>
              </v-card-title>
            </div>
            <div
              class="separator separator-solid separator-white opacity-20"
            ></div>
            <div class="card-body text-dark">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group row">
                    <label class="col-4 col-form-label text-white">Name</label>
                    <div class="col-8">
                      <b-form-input
                        v-model="$v.name.$model"
                        class="form-control"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                        placeholder=""
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        Name is required.
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-5 col-form-label text-white"
                      >Status</label
                    >
                    <div class="col-7">
                      <b-form-select
                        v-model="status"
                        :options="statusOptions"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-form-label text-white"
                      >Use Tags to sort and identify your products:</label
                    >
                    <v-combobox
                      v-model="chips"
                      :items="tagNames"
                      chips
                      clearable
                      :search-input.sync="chipsearch"
                      label="Enter Tags followed by Enter key. (TAGS are a OR Logic)"
                      multiple
                      hide-selected
                      solo
                      @change="chipsearch = ''"
                    >
                      <template
                        #selection="{
                          attrs,
                          item,
                          select,
                          selected,
                          parent,
                        }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="parent.selectItem(item)"
                        >
                          <strong>{{ item }}</strong
                          >&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6"></div>
              </div>
            </div>
          </v-card>
        </div>

        <div class="col-lg-6">
          <v-card class="mx-auto h-100">
            <img
              id="image_cover"
              style="width: 100%; max-height: 480px;"
              :src="
                main_image_url != ''
                  ? baseurl + main_image_url
                  : '/media/product-placeholder.jpg'
              "
            />
            <v-card-actions>
              <v-btn text>Main Image</v-btn>
              <b-form-file
                v-model="main_image"
                :state="Boolean(main_image)"
                :placeholder="
                  main_image_name == '' ? 'Upload Image' : main_image_name
                "
                drop-placeholder="Drop file here..."
                accept="image/*"
                @change="imageChange"
              ></b-form-file>

              <v-spacer></v-spacer>
              <a class="btn btn-warning ml-4" @click="imageDelete">Delete</a>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <div
        class="v-card--material pa-3 px-5 mt-3 mb-5 v-card v-sheet theme--light v-card--material--has-heading"
      >
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label class="col-3 col-form-label"> Description</label>

              <div class="col-9">
                <ckeditor
                  v-model="description"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>

                <pre class="mt-3 mb-0"></pre>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group row">
              <label class="col-3 col-form-label"> Short Description</label>

              <div class="col-9">
                <ckeditor
                  v-model="short_description"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>

                <pre class="mt-3 mb-0"></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import GetCompanyTags from '@/graphql/queries/GetCompanyTags.gql'
import UpdateFullChannel from '@/graphql/mutations/UpdateFullChannel.gql'
import UploadFile from '@/graphql/mutations/UploadFile.gql'
import DeleteChannelAccesses from '@/graphql/mutations/DeleteChannelAccesses.gql'
import DeleteChannel from '@/graphql/mutations/DeleteChannel.gql'
import GetChannelsCount from '@/graphql/queries/GetChannelsCount.gql'
import GetChannel from '@/graphql/queries/GetChannel.gql'

// import gql from 'graphql-tag'

export default {
  mixins: [validationMixin],
  data() {
    return {
      defaultChannel: true,
      chipsearch: '',
      chips: [],
      name: '',
      status: 'enabled',
      channelAccesses: [],
      supplierDefaultChannel: '',
      statusOptions: [
        {
          value: 'enabled',
          text: 'Enabled',
        },
        {
          value: 'disabled',
          text: 'Disabled',
        },
        {
          value: 'pending',
          text: 'Pending',
        },
      ],
      main_image_id: '',
      main_image_url: '',
      main_image: null,
      main_image_name: '',
      description: '',
      short_description: '',
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            '|',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
        },
      },
      baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
    }
  },
  validations: {
    name: {
      required,
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    tagNames: function() {
      const tagHeader = [{ header: 'Select an option or create one' }]
      const allTags = this.tags ? this.tags.map((t) => t.name) : []
      return [].concat(tagHeader, allTags)
    },
  },
  created() {
    setTimeout(() => {
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.$apollo
          .query({
            query: GetChannelsCount,
            variables: {
              channelId: this.$route.params.id,
              supplierId: this.$store.state.auth.user.company_relation,
            },
          })
          .then((res) => {
            if (res.data == 0) {
              this.$router.push('/pim/channels')
            } else {
              this.$apollo
                .query({
                  query: GetChannel,
                  variables: {
                    id: this.$route.params.id,
                  },
                })
                .then((res) => {
                  if (
                    res.data.channel.supplier.id !=
                    this.$store.state.auth.user.company_relation
                  ) {
                    this.$router.push('/pim/channels')
                  }
                  this.name = res.data.channel.name
                  this.status = res.data.channel.status
                  this.channelAccesses = res.data.channel.channel_accesses
                  this.supplierDefaultChannel =
                    res.data.channel.supplier.default_channel
                  if (this.supplierDefaultChannel == this.$route.params.id) {
                    this.defaultChannel = true
                  } else {
                    this.defaultChannel = false
                  }
                  if (res.data.channel.main_image) {
                    this.main_image_id = res.data.channel.main_image.id
                    this.main_image_url = res.data.channel.main_image.url
                    this.main_image_name =
                      res.data.channel.main_image.name +
                      res.data.channel.main_image.ext
                  }
                  this.description = res.data.channel.description
                  this.short_description = res.data.channel.short_description
                  this.chips = res.data.channel.tags.map((t) => t.name)
                })
            }
          })
      }
    }, 1000)
  },
  methods: {
    ...mapActions([
      'getChannelById',
      'removeChannel',
      'deleteFile',
      'getChannelTotalCount',
    ]),
    imageDelete() {
      this.main_image = ''
      this.main_image_name = ''
      this.main_image_url = ''
    },
    channelDelete() {
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Are you sure?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async (value) => {
            if (value == true) {
              //async ecom data
              if (this.channelAccesses.length > 0) {
                this.channelAccesses.map(async (channelAccess) => {
                  await this.$apollo.mutate({
                    mutation: DeleteChannelAccesses,
                    variables: {
                      id: channelAccess.id,
                    },
                  })
                })
              }
              await this.$apollo.mutate({
                mutation: DeleteChannel,
                variables: {
                  id: this.$route.params.id,
                },
              })
              Swal.fire({
                title: '',
                text: 'The channel has been successfully deleted!',
                icon: 'success',
                confirmButtonClass: 'btn btn-secondary',
              })
              this.$router.push('/pim/channels')
            }
          })
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name]
      return $dirty ? !$error : null
    },
    imageChange(e) {
      let target = e.target || e.srcElement
      let files = target.files
      let fr = new FileReader()
      fr.onload = function() {
        document.getElementById('image_cover').src = fr.result
      }
      fr.readAsDataURL(files[0])
    },
    async submit(e) {
      e.preventDefault()
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      // guard
      if (
        this.$route.params.id === '' ||
        this.$route.params.id === undefined ||
        this.$route.params.id === null
      ) {
        return
      }

      const channelUpdate = {
        name: this.name,
        status: this.status,
        description: this.description,
        short_description: this.short_description,
      }
      const hasImageToUpload = this.main_image instanceof File
      const isDeletingImage = this.main_image === ''
      const hasDeletableImage =
        this.main_image_id !== undefined && this.main_image_id !== ''

      try {
        if ((hasImageToUpload || isDeletingImage) && hasDeletableImage) {
          await this.deleteFile({
            id: this.main_image_id,
          })
        }

        if (hasImageToUpload) {
          await this.$apollo.mutate({
            mutation: UploadFile,
            variables: {
              contentType: 'channel',
              contentId: this.$route.params.id,
              field: 'main_image',
              file: this.main_image,
            },
          })
        }

        await this.$apollo.mutate({
          mutation: UpdateFullChannel,
          variables: {
            id: this.$route.params.id,
            channelUpdate,
            searchTags: this.chips,
          },
        })

        // refreshes tags incase a new one was created
        this.$apollo.queries.tags.refetch()

        Swal.fire({
          title: '',
          text: 'The channel has been successfully updated!',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
      } catch (error) {
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
  },
}
</script>
